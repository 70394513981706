/**!
 * Modal v1.0.20180902
 */
/*
 *
 * @type: modal type. supported types: 'default'|'iframe'|'custom'. default value is 'default', can be omitted
 * @src: "iframe" source. only necessary for type='iframe'
 * @contentId: domId of custom modal template. necessary for type='custom'
 * @class: for custom class, you can control dom by the class
 * @title: string, optional, only for type='default'
 * @desc: string, optional, only for type='default'
 * @buttons: buttons array, optional, only for type='default'.
 * @button:
 * 		@title: button label, string. optional.
 * 		@type: button type, string. supported types: 'yes'|'no'|'hide'|(custom). 
 * 			'yes': no behavior. positive style. you can custom style by overriding '.neu-modal__btn_yes' 
 * 			'no': no behavior. negative style. you can custom style by overriding '.neu-modal__btn_no'
 * 			'hide': hide modal. positive style. you can custom style by overriding '.neu-modal__btn_hide'
 * 		{custom}: close modal. positive style. you can custom style by overriding '.neu-modal__btn_{custom}'
 * 		@callback: callback function.
 * @width: the width of the modal, necessary for type='iframe', optional for other types.
 * @height: the height of the modal, necessary for type='iframe', optional for other types.
 * @isCloseButton: boolean, optional. supported types: (empty)|true|false. 'true' only affect for type='default'.
 * 		(empty): not display the close button.
 * 		true:  display close button. click the button will close the modal. only affect for type='default'.
 * 		false:  display close button. click the button will hide the modal.
 * @closeButtonAction: callback function when click close icon.
 *
 * */
(function modalDefinition()
{
	function NeuModal()
	{
		return function ()
		{
			var _this = this;
			var _overlayIdIndex = 0;
			var _maxIndex = 10000;
			var _modalTemplates = [];
			var _modalParam = "";
			var overlayIdPrefix = "neu-overlay";

			this.show = function (modalParam)
			{
				var modalId = null;
				if (typeof(modalParam) == "object")
				{
					var paramStr = JSON.stringify(modalParam);
					_modalParam = modalParam;	// TODO #1 shouldn't assign temp variable to global variable


					// 如果这个modal已经存在, 则直接显示.
					modalId = handleShowModal(paramStr, false);
				}
				else
				{
					modalId = handleShowModal(modalParam, true);
				}
				return modalId;
			};

			this.hide = function (param, callback)
			{
				handleHideOrCloseModal(param, false, callback);
			}

			this.close = function (param, callback)
			{
				handleHideOrCloseModal(param, true, callback);
			}

			function handleShowModal(modalStr, isId)
			{
				var modalTemplate = {};
				var existModal = false;
				var existParam = "";

				if (_modalTemplates.length > 0)
				{
					for (var i = 0; i < _modalTemplates.length; i++)
					{
						if (!isId)
						{
							existParam = _modalTemplates[i].paramStr;
						}
						else
						{
							existParam = _modalTemplates[i].id;
						}
						if (existParam === modalStr)
						{
							processIndex(_modalTemplates[i]);
							if (!isId)
							{
								existModal = true;
							}
							break;
						}
					}
				}

				// Create dom only for the first time
				if (!existModal && !isId)
				{
					modalTemplate.paramStr = modalStr;
					modalTemplate.id = overlayIdPrefix + _overlayIdIndex;
					_modalTemplates.push(modalTemplate);
					createDom();
					processIndex(modalTemplate);
				}
				return modalTemplate.id;
			}

			function processIndex(currentModalTemplate)
			{
				var allOverlay = document.querySelectorAll(".neu-overlay");
				for (var i = 0; i < allOverlay.length; i++)
				{
					if (allOverlay[i].style.zIndex && hasClass(allOverlay[i], "is-neu-overlay-open"))
					{
						var currentIndex = parseInt(allOverlay[i].style.zIndex);
						_maxIndex = Math.max(_maxIndex, currentIndex);
					}
				}
				var currentModal = document.querySelector("#" + currentModalTemplate.id);
				if (!hasClass(currentModal, "is-neu-overlay-open"))
				{
					currentModal.className += " is-neu-overlay-open";
					_maxIndex++;
					currentModal.style.zIndex = _maxIndex;
				}
				if (!hasClass(document.body, "is-neu-overlay-open"))	// FIXME consider to introduce function:addClass()
				{
					document.body.className += " is-neu-overlay-open";
				}
				_overlayIdIndex++;
			}

			function handleHideOrCloseModal(param, isClose, callback)
			{
				if (typeof(param) == "object")
				{
					var paramStr = JSON.stringify(param);
					for (var i = 0; i < _modalTemplates.length; i++)
					{
						if (_modalTemplates[i].paramStr === paramStr)
						{
							var currentModal = document.querySelector("#" + _modalTemplates[i].id);
							if (isClose)
							{
								document.body.removeChild(currentModal);
								_modalTemplates.splice(i, 1);
								break;
							}
							else
							{
								removeClass(currentModal, "is-neu-overlay-open");
								currentModal.style.zIndex = -1;
							}
						}
					}
				}
				else
				{
					for (var i = 0; i < _modalTemplates.length; i++)
					{
						if (_modalTemplates[i].id === param)
						{
							var currentModal = document.querySelector("#" + _modalTemplates[i].id);
							if (isClose)
							{
								document.body.removeChild(currentModal);
								_modalTemplates.splice(i, 1);
							}
							else
							{
								removeClass(currentModal, "is-neu-overlay-open");
								currentModal.style.zIndex = -1;
							}

						}
					}
				}

				isClose && _overlayIdIndex > 0 && _overlayIdIndex--;

				if (_maxIndex <= 10001)
				{
					removeClass(document.body, "is-neu-overlay-open");
					_maxIndex = 10000;
				}
				else
				{
					_maxIndex--;
				}
				callback && callback();
			}

			function createDom()
			{
				//Overlay
				var overlayNode = createElement(_modalParam.class ? "neu-overlay neu-overlay_theme " +  _modalParam.class:  "neu-overlay neu-overlay_theme", document.body, false);
				overlayNode.id = overlayIdPrefix + _overlayIdIndex;
				var overlayId = overlayNode.id;

				//modal wrapper
				var modalWrapNode = createElement("neu-modal-wrap", overlayNode);
				var container = document.getElementById(overlayId);
				if (!_modalParam.type || _modalParam.type == "default")	// TODO See #1
				{
					//type: default
					var modalNode = createElement("neu-modal neu-modal_theme neu-modal_width-default", modalWrapNode, true);
					modalNode.innerHTML = getDefaultModalTmpl();
					// binding click event
					var btns = container.querySelectorAll(".neu-modal__btn");
					for (var i = 0; i < btns.length; i++)
					{
						var elBtn = btns[i];
						var elBtnConfig = _modalParam.buttons[parseInt(elBtn.getAttribute("data-index"), 10)];
						var action = elBtnConfig["type"] == "hide" ? "hide" : "close";
						(function (action, callback)
						{
							elBtn.addEventListener("click", function ()
							{
								_this[action](overlayId, callback);
							});
						}(action, elBtnConfig["callback"]));
					}
					_modalParam.isCloseButton != undefined && isCloseButton(modalNode, overlayId, true);
					modalWrapNode.style.height = modalNode.clientHeight + "px";
				}
				else if (_modalParam.type == "iframe")
				{
					//type: iframe
					var modalNode = createElement("neu-modal neu-modal_theme neu-modal_width-full", modalWrapNode, true);
					var iframeNode = createElement("neu-overlay__iframe", modalNode, false, "iframe");
					iframeNode.setAttribute('frameborder', '0', 0);
					iframeNode.src = _modalParam.src;
					_modalParam.isCloseButton != undefined && isCloseButton(modalNode, overlayId, false);
					modalWrapNode.style.height = iframeNode.clientHeight + "px";

				}
				else if (_modalParam.type == "custom")
				{
					//type: custom
					var customModalNode = document.querySelector("#" + _modalParam.contentId);
					elementAttrProcess(customModalNode.className + " neu-modal neu-modal_theme", modalWrapNode, true, customModalNode);
					customModalNode.style.display = "block";
					_modalParam.isCloseButton != undefined && isCloseButton(customModalNode, overlayId, false);
					modalWrapNode.style.height = customModalNode.clientHeight + "px";
				}
			}

			function getDefaultModalTmpl()
			{
				var title = '';
				var desc = '';
				var btns = '';
				if (_modalParam.title != null && _modalParam.title != "")
				{
					title += '<div class="neu-modal__title">' + _modalParam.title + '</div>';
				}
				if (_modalParam.desc != null && _modalParam.desc != "")
				{
					desc += '<div class="neu-modal__desc">' + _modalParam.desc + '</div>';
				}
				if (_modalParam.buttons && _modalParam.buttons.length > 0)
				{
					btns += '<div class="neu-modal__btns">';
					//isArray
					for (var i = 0; i < _modalParam.buttons.length; i++)
					{
						var btn = _modalParam.buttons[i];
						btns += '<a href="javascript:void(0);" data-index="' + i + '" class="neu-modal__btn neu-modal__btn_theme neu-modal__btn_' + btn["type"].toLowerCase() + '">' + btn["title"].toUpperCase() + '</a>';
					}
					btns += '</div>';
				}
				return title + desc + btns;
			}

			function isCloseButton(parent, id, isClose)
			{
				var closeEl = createElement("neu-close neu-close_theme", parent, false, "i");
				var action = "hide";
				if(isClose && _modalParam.isCloseButton)
					action = "close";
				closeEl.addEventListener("click", function ()
				{
					_this[action](id, _modalParam.closeButtonAction);
				});
			}

			//Tools function
			function hasClass(obj, cls)
			{
				return (' ' + obj.className + ' ').indexOf(' ' + cls + ' ') > -1;
			}

			function removeClass(obj, cls)
			{
				if (hasClass(obj, cls))
				{
					var reg = new RegExp('(\\s|^)' + cls + '(\\s|$)');
					obj.className = obj.className.replace(reg, ' ');
				}
			}

			function setStyle(modal)
			{
				if (_modalParam.width && !isNaN(parseInt(_modalParam.width)))
				{
					modal.style.width = parseInt(_modalParam.width) + "px";
				}
				if (_modalParam.height && !isNaN(parseInt(_modalParam.height)))
				{
					modal.style.height = parseInt(_modalParam.height) + "px";
				}
			}

			function elementAttrProcess(className, parent, style, node)
			{
				className && (node.className = " " + className);
				style && setStyle(node);
				parent && parent.appendChild(node);
			}

			function createElement(className, parent, style, elName)
			{
				var node = elName ? document.createElement(elName) : document.createElement("div");
				elementAttrProcess(className, parent, style, node);
				return node;
			}
		}
	}

	if (window)
	{
		if (window.NEU && window.NEU.ui)
		{
			window.NEU.ui.modal = new (NeuModal())();
		}
		else
		{
			window.NeuModal = new (NeuModal())();
		}
	}
	// Compatible with webpack
	if(typeof exports === 'object' && typeof module === 'object')
	{
		module.exports = new (NeuModal())();
	}
})();
